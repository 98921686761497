
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    TmModal,
  },
  setup() {
    const items = [
      {
        image: require('@/assets/images/gmail-md-logo.svg'),
        title: 'Gmail',
        text: 'Chat with your customers, partners and leads using Gmail inbox.',
      },
      {
        image: require('@/assets/images/outlook-logo.svg'),
        title: 'Outlook',
        text: 'Chat with your customers, partners and leads using Outlook inbox.',
      },
    ]

    return {
      items,
    }
  },
})
