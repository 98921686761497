import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60cf2334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-between" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "subhead-semi-bold-16 mb-6px" }
const _hoisted_4 = { class: "body-text-regular-14 lh-20 distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "connectInbox",
    "modal-title": "Connect new email inbox",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "connect-inbox__item"
          }, [
            _createElementVNode("img", {
              src: item.image
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.text), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}